import * as React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';

const IndexPage = (props) => {
  const keywords = "Leituras no Litoral, incentivo à leitura, contações de histórias, rodas de leituras, oficinas de contação de histórias, apresentações de espetáculo narrativo, intervenções literárias, Matinhos, Pontal do Paraná";
  const description = "O projeto Leituras no Litoral realizará ações de incentivo à leitura nas cidades de Matinhos e Pontal do Paraná em 2023 através de contações de histórias, rodas de leituras, oficinas de contação de histórias, apresentações de espetáculo narrativo e intervenções literárias.";

  return (
    <Layout
      pageTitle=""
      keywords={keywords}
      description={description}
      {...props}
    >

      <div className="wave wave--left wave--1"></div>

      <div className='content-card '>
        <div className='content-card__body'>
          <h2>O que é</h2>

          <p>O projeto <strong>Leituras no Litoral</strong> foi aprovado no Edital PROFICE 2022 da Secretaria de Estado da Cultura do Governo do Estado do Paraná e conta com o apoio da COPEL.</p>

          <p>O projeto realizará <strong>ações de incentivo à leitura nas cidades de Matinhos e Pontal do Paraná</strong> em 2023 através das seguintes ações: contações de histórias, rodas de leituras, oficinas de contação de histórias, apresentações de espetáculo narrativo e intervenções literárias.</p>

          <p>Todas as ações mencionadas se utilizarão de <strong>histórias pertencentes à tradição oral da cultura popular brasileira</strong>, assim como a literatura autoral de autores negros e negras, como referências bibliográficas.</p>

          <p><strong>Leituras no Litoral pretende estimular a leitura da literatura</strong> apresentando-a em diferentes formatos narrativos e estilísticos contribuindo com a formação de cidadãos mais leitores e suprindo uma lacuna existente em algumas cidades do litoral paranaense no que tange à oferta de diferentes produtos culturais, tanto para a comunidade litorânea quanto aos veranistas.</p>

          <p>Todas as ações do projeto serão <strong>gratuitas</strong>.</p>
        </div>
      </div>

      <div className="wave wave--left wave--2"></div>

      <div className='content-card'>
        <div className='content-card__body'>
          <h2>Ficha técnica</h2>

          <ul>
            <li><strong>Idealização e coordenação do projeto:</strong> Rafael Di Lari</li>
            <li><strong>Curador:</strong> Cido Vasconcelos</li>
            <li><strong>Articuladora local:</strong> Ariani Alencar</li>
            <li><strong>Intervenção literária:</strong> Cris Nagel e Lilyan de Souza</li>
            <li><strong>Espetáculo narrativo:</strong> Grupo Baquetá</li>
            <li><strong>Oficina de Contação de histórias:</strong> Cleo Cavalcantty</li>
            <li><strong>Contação de histórias:</strong> Rafael Di Lari</li>
            <li><strong>Rodas de leitura:</strong> Manu Assini</li>
            <li><strong>Registro (foto):</strong> Vinícius Tavares</li>
            <li><strong>Registro (vídeo):</strong> Guapê Cultural (Daya Woman)</li>
            <li><strong>Web designer:</strong> Lucas Pereira</li>
            <li><strong>Designer gráfico:</strong> Manu Assini</li>
            <li><strong>Coordenação de mídias sociais:</strong> Rodrigo Hayalla</li>
            <li><strong>Assessoria de imprensa:</strong> Mem Comunicação</li>
            <li><strong>Realização:</strong> Barbas de Molho Produções Artísticas</li>
          </ul>
        </div>
      </div>      
    </Layout>
  )
}

export default IndexPage;